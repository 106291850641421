<template>
  <div class="app__login">
    <div class="app__login--clever"></div>
    <div class="app__login--form">
      <form @submit.prevent="forgetHandler">
        <div style="position: relative; min-width: 400px">
          <div id="logo"></div>
          <h1>КАБИНЕТ КЛИЕНТА</h1>
          <div v-if="forgetSuccess">
            <h2 style="margin-bottom: 20px">
              В ближайшее время ваш диспетчер отправит данные для входа
            </h2>
            <Lottie :options="defaultOptions" :width="128" :height="128" />
          </div>
          <div v-else>
            <h2>Введите логин, на который был зарегистрирован аккаунт</h2>
            <div class="form-input">
              <label for="login">ИНН организации</label>
              <masked-input
                v-model="login"
                type="text"
                id="login"
                mask="1111111111"
                placeholder="7777777777"
                required="required"
              />
            </div>
            <form-button
              title="ДАЛЕЕ"
              style="margin-top: 20px"
              class="button__yellow"
              :disabled="disabled"
            ></form-button>
          </div>
          <ui-message
            @messageClose="messageClose"
            :message="message"
            :messageProp="messageProp"
          />
          <div id="comeBack">
            <router-link to="/auth">Вернуться</router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FormButton from "../components/form/form-button.vue";
import UiMessage from "../components/ui/ui-message.vue";
// MaskedInput
import MaskedInput from "vue-masked-input";
// Animation
import Lottie from "vue-lottie";
import animationData from "../assets/animations/check-circle.js";

export default {
  name: "ForgetPage",

  components: {
    FormButton,
    UiMessage,
    MaskedInput,
    Lottie
  },

  data() {
    return {
      login: "",
      disabled: false,
      message: false,
      messageProp: {},
      forgetSuccess: false,
      defaultOptions: { animationData: animationData, loop: false },
      animationSpeed: 1
    };
  },

  computed: {
    clearLogin() {
      let clearLogin = "";
      if (this.login) {
        clearLogin = this.login.replace(/[^0-9]/g, "");
      }
      return clearLogin;
    }
  },

  methods: {
    clearForm() {
      this.login = "";
      this.disabled = false;
    },

    forgetHandler() {
      this.disabled = true;
      this.axios
        .post(`/client/request/password/${this.clearLogin}`)
        .then((response) => {
          console.log(response);
          if (response.data.response) {
            this.forgetSuccess = true;
          } else if (response.data.error === "CLIENT_NOT_FOUND") {
            (this.message = true),
              (this.messageProp = {
                class: "alert__danger",
                title: "Ошибка",
                text: "Такой записи не существует"
              });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.clearForm();
        });
    },

    messageClose() {
      this.message = false;
      this.messageProp = {};
    }
  }
};
</script>
